import React, { useState } from "react";

import api from "../../services/global/api-paradigma";
import image5 from "../../images/image-5.png";
import Loading from "../loading";
import Message from "../modals/message";

const VideoTrackCoupon = ({
  modals,
  setPrice,
  setModal,
  setDiscount,
  setCoupon,
  setCodeId,
  setBlackFriday,
}) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [isLoading, setLoading] = useState(false);
  const [openMessage, setopenMessage] = useState(false);
  const [success, setSuccess] = useState();
  const [message, setMessage] = useState("Cupom Inválido.");
  const [codeValue, setCodeValue] = useState({
    code: "" || urlParams.get("coupon"),
  });
  const onChange = (ev) => {
    const { name, value } = ev.target;
    setCodeValue({ ...codeValue, [name]: value });
  };

  const onSubmit = (ev) => {
    try {
      ev.preventDefault();
      setLoading(true);
      setCoupon(codeValue);
      // try {
      //   window.umami(`Alguém usando cupom, ${codeValue.code}`);
      // } catch (err) {
      //   console.info("?", err);
      // }
      api
        .post("/api/v2/coupons/verify", codeValue)
        .then(async (response) => {
          setLoading(false);
          if (response.data.coupon.active) {
            setSuccess(true);
            await setBlackFriday(true);
            const discountRes = response.data.coupon.coupon.percent_off;
            await setDiscount(discountRes);
            await setPrice(modals.price - (modals.price * discountRes) / 100);
            setCodeId(response.data.coupon.id);
            setTimeout(() => setModal(1), 3000);
          } else {
            setLoading(false);
            setopenMessage(true);
            setSuccess(false);
            setMessage("Cupom está vencido ou não é mais valido.");
          }
        })
        .catch((error) => {
          setLoading(false);
          setopenMessage(true);
          setSuccess(false);
          console.error(error);
        });
      setopenMessage(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="flex-center margin-bottom margin-medium">
        <img src={image5} loading="lazy" alt="" className="modal-image" />
      </div>

      {isLoading ? (
        <Loading />
      ) : openMessage ? (
        success ? (
          <Message text={`Cupom de ${modals.discount}% aplicado!`} success />
        ) : (
          <Message text={message} success={false} />
        )
      ) : null}

      <div className="form-wrapper w-form">
        <form
          id="email-form-2"
          name="email-form-2"
          data-name="Email Form 2"
          method="get"
          className="form"
        >
          <div className="input-group">
            <label
              htmlFor="code"
              className="text-size-medium margin-botton margin-xxsmall"
            >
              Insira seu cupom de desconto
            </label>
            <input
              type="text"
              className="text-field w-input"
              maxLength="256"
              name="code"
              data-name="Name 2"
              placeholder="Seu Cupom"
              id="code"
              value={codeValue.code}
              onChange={onChange}
              required
            />
          </div>
          <button
            type="submit"
            onClick={onSubmit}
            data-w-id="4a6c60a9-cc98-d08e-2245-305c44f31f98"
            className="button-modal margin-top margin-xxsmall w-inline-block"
          >
            <span>Aplicar</span>
          </button>
        </form>
      </div>
    </>
  );
};

export default VideoTrackCoupon;
