export const resizeImage = (url, screenWidth) => {
  const urlBase =
    "https://img.paradigma.education/img/https://storage.googleapis.com/paradigma-dev-8d6b0.appspot.com/";

  url = typeof url === 'string' ? url : String(url);

  if (url.startsWith(urlBase)) {
    return url;
  }

  if (url.startsWith("https://img.paradigma.education/img/https://storage.googleapis.com/paradigma-firebase.appspot.com/")) {
    return url;
  }

  const getUrl = (url) => {
    if (url.startsWith(urlBase)) {
      return urlBase;
    }
    if (url.startsWith("https://img.paradigma.education/img/https://storage.googleapis.com/paradigma-firebase.appspot.com/")) {
      return "https://img.paradigma.education/img/https://storage.googleapis.com/paradigma-firebase.appspot.com/";
    }
    if (url.startsWith("https://firebasestorage.googleapis.com/v0/b/paradigma-dev-8d6b0.appspot.com/")) {
        return `https://img.paradigma.education/img/https://storage.googleapis.com/paradigma-dev-8d6b0.appspot.com/`;
    }
    return `https://img.paradigma.education/img/https://origin-vs.b-cdn.net/paradigmaeducation/`
  }

  let newUrlAsis;
  let result;

  switch(true) {
    case /flame.*\.(png|gif|jpe?g)/g.test(url):
      result = url.match(/flame.*\.(png|gif|jpe?g)/g)[0];
      newUrlAsis = getUrl(url) + result + "/asis";
      break;
    case /thumbnails[^?]+\?/.test(url):
      result = url.match(/thumbnails[^?]+\?/)[0];
      newUrlAsis = getUrl(url) + result.slice(0, -1) + "/asis";
      break;
    case /thumbnails.*$/g.test(url):
      result = url.match(/thumbnails.*$/g)[0];
      newUrlAsis = getUrl(url) + result + "/asis";
      break;
    default:
      newUrlAsis = getUrl(url) + url + "/asis";
  }
  return newUrlAsis;
};
