import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber} from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

import api from "../../services/global/api-feargreed";
import LoadingGif from "../../images/loading-button.gif";
import Message from "../modals/message";
import { mapPhone } from "../../utils";

import "react-phone-number-input/style.css";

const PhoneInputInputComponent = React.forwardRef((props, ref) => {
  return (
    <input 
      {...props} 
      ref={ref} 
      style={{
        border: 'none',
        outline: 'none',
        borderRadius: '0',
        boxShadow: 'none',
      }}
    />
  );
});

const PreSalesForm = (script) => {
  console.log(script);
  const initialValue = {
    name: "",
    email: "",
  };
  const [values, setValues] = useState(initialValue);
  const [text, setText] = useState("");
  const [phone, setPhone] = useState("");
  const [success, setSuccess] = useState(false);
  const [visible, setVisible] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const onChange = (ev) => {
    const { name, value } = ev.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = async (ev) => {
    try {
      ev.preventDefault();
      setSubmitDisabled(true);
      setLoading(true);

      let body = {
        customer: {
          email: values.email,
          name: values.name,
          phone: {
            ddd: mapPhone(phone)?.ddd,
            number: mapPhone(phone)?.number,
          },
        },
      };

      const preSalesRes = body;

      if (preSalesRes) {
        const validEmail = /.+@.+\.[A-Za-z]+$/.test(values.email);
        const phoneString = phone.toString();
        const validPhone = isValidPhoneNumber(phoneString);

        if (!validEmail || !validPhone) {
          setLoading(false);
          setVisible(true);
          setSuccess(false);
          setSubmitDisabled(false);
          setText(`Digite um ${!validEmail ? "email" : "telefone"} válido.`);
          return;
        }

        setLoading(true);
        setVisible(true);
        setSuccess(true);
        setText("Você será direcionado para nosso grupo do whatsapp!");
        await api.post("/api/v2/leads", {
          email: preSalesRes.customer.email,
          name: preSalesRes.customer.name,
          phone: preSalesRes.customer.phone.number,
          landing: window.location.href,
        });
        setTimeout(() => {
          window.location.href = script.script;
        }, 1500);
      }
    } catch (err) {
      setLoading(false);
      setVisible(true);
      setSuccess(false);
      setSubmitDisabled(false);
      setText(
        err.response
          ? err.response.data.error
          : "Preencha todos os campos obrigatórios!"
      );
    }
  };

  return (
    <>
      <div className="form-wrapper w-form" style={{ marginTop: "24px" }}>
        <div>
          <p className="text-size-medium text-color-dark-gray">
            Digite seu e-mail abaixo e garanta uma vaga na trilha{" "}
          </p>
        </div>

        {visible === true ? <Message text={text} success={success} /> : null}

        <form
          id="email-form-2"
          name="email-form-2"
          data-name="Email Form 2"
          method="get"
          className="form"
        >
          <div className="input-group margin-xsmall margin-vertical">
            <input
              id="name"
              name="name"
              type="text"
              className="text-field w-input"
              data-name="Email 3"
              placeholder="Seu nome"
              required
              onChange={onChange}
            />
          </div>

          <div className="input-group margin-xsmall margin-vertical">
            <input
              id="email"
              name="email"
              type="email"
              className="text-field w-input"
              maxLength="256"
              data-name="Email 3"
              placeholder="exemplo@email.com"
              required
              onChange={onChange}
            />
          </div>

          <div className="input-group margin-xsmall margin-vertical">
            <PhoneInput
              inputComponent={PhoneInputInputComponent}
              flags={flags}
              value={phone}
              onChange={setPhone}
              withCountryCallingCode
              className={"text-field w-input"}
            />
          </div>

          <button
            type="submit"
            data-w-id="1c0429cf-d1a1-5a00-743c-bc6f3c8d4e6c"
            className="button-modal margin-top margin-xsmall w-inline-block"
            disabled={submitDisabled}
            onClick={onSubmit}
          >
            {!loading ? (
              <span>Entrar no grupo do whatsapp</span>
            ) : (
              <div className="loader">
                <img
                  style={{ width: "20px" }}
                  src={LoadingGif}
                  alt="loading..."
                />
              </div>
            )}
          </button>
        </form>
      </div>
    </>
  );
};

export default PreSalesForm;
