import React, { useState } from "react";
import * as Sentry from "@sentry/react";

import {
  TextMaskCEP,
  TextMaskCardCvc,
  TextMaskCardCPF,
  TextMaskCardNumber,
  validCnpj,
  validCpf,
} from "../../../utils";
import api from "../../../services/global/api-paradigma";
import download from "../../../images/download-1-1.png";
import LoadingGif from "../../../images/loading-button.gif";
import Message from "../message";
import { validCreditCardNumber } from "../../../utils/validateCard";
import { validCep } from "../../../utils/validateCep";
import { TextMaskCardExp } from "../../../utils/TextMaskCardExp";
import { states } from "../../../utils/state";
import { setCookie } from "../../../utils/cookie";

const IuguForm = ({
  modals,
  setPrice,
  setInstallments,
  installmentsOptions,
  getSelectInstallmentsDefaultValue,
}) => {
  window.Iugu.setAccountID("8B572DEA6B52461C97CEF8DDA90B5C50");
  // window.Iugu.setTestMode(true);
  var formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  const initialValue = {
    neighborhood: "",
    street: "",
    streetNumber: "",
    postal_code: "",
    state: "",
    country: "BR",
    city: "",
    phone: "",
    telephone: "",
    documentNumber: "",
    email: "",
    pplan: modals.pplan,
    paymentMethod: "credit_card",
    cardToken: "",
    installments: modals.installments,
  };
  const [values, setValues] = useState(initialValue);
  const [cpfNumber, setCpfNumber] = useState("");
  const [cardExp, setCardExp] = useState("");
  const [text, setText] = useState("");
  const [success, setSuccess] = useState(false);
  const [visible, setVisible] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [cpfNumNotValid, setCpfNumNotValid] = useState(false);
  const [cardNumberNotValid, setCardNumberNotValid] = useState(false);
  const [expMonthAndYearNotValid, setExpMonthAndYearNotValid] = useState(false);
  const [cepNotValid, setCepNotValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardToken, setCardToken] = useState("");

  const onChange = (ev) => {
    const { name, value } = ev.target;

    switch (name) {
      case "documentNumber":
        setCpfNumber(value);
        const isValidCpf =
          validCnpj(value.replace(/(\.|-|\/)/g, "").trim()) ||
          validCpf(value.replace(/(\.|-|\/)/g, "").trim());
        setCpfNumNotValid(!isValidCpf);
        break;

      case "card-number":
        const isValidCard = validCreditCardNumber(value);
        setCardNumberNotValid(!isValidCard);
        break;

      case "postal_code":
        const isValidCep = validCep(value);
        setCepNotValid(!isValidCep);
        break;

      case "card-exp":
        setCardExp(value);
        setExpMonthAndYearNotValid(false);
        break;

      default:
        break;
    }

    setValues({ ...values, [name]: value });
  };

  const handleChange = async (event) => {
    setCpfNumber("");
    setVisible(false);
    setText("");
    setInstallments(event.target.selectedIndex + 1);
    const currentInstallments = parseFloat(event.target.value);
    await setPrice(parseFloat(currentInstallments.toFixed(2)));
  };

  const fillAddress = (data) => {
    setValues({
      ...values,
      street: data.logradouro,
      state: data.uf,
      city: data.localidade,
      neighborhood: data.bairro,
    });
  };

  const onBlurCep = (ev) => {
    setLoading(true);
    const { value } = ev.target;
    const cep = value?.replace(/[^0-9]/g, "");
    if (cep?.length !== 8) {
      setLoading(false);
      setVisible(true);
      setSuccess(false);
      setText("CEP inválido.");
      return;
    }

    api
      .get(`api/v2/cep/${cep}`)
      .then((res) => {
        fillAddress(res.data);
        setLoading(false);
        if (res.data.cep) {
          setVisible(true);
          setSuccess(true);
          setText("CEP válido!");
        } else {
          setVisible(true);
          setSuccess(false);
          setText("CEP inválido!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateFields = (fields, values) => {
    return fields.every((fieldName) => values[fieldName].trim() !== "");
  };

  const toCent = (amount) => {
    const str = amount.replace(",", ".");
    return str.length < 3 || str[str.length - 3] === "."
      ? Number(str.replace(".", ""))
      : Number(str.replace(".", "")) * 100;
  };

  const onSubmit = async (event) => {
    var tokenResponseHandler = async function (data) {
      if (data.errors) {
        alert("Erro salvando cartão: " + JSON.stringify(data.errors));
      } else {
        await setCardToken(data.id);
      }
    };
    setSubmitDisabled(true);
    setLoading(true);
    await window.Iugu.createPaymentToken(event.target, tokenResponseHandler);

    event.preventDefault();
    const isFormValid = validateFields(
      [
        "street",
        "postal_code",
        "state",
        "city",
        "documentNumber",
        "card-exp",
        "cvv",
        "holder-name",
      ],
      values,
    );

    if (!cardToken) {
      setLoading(false);
      setVisible(true);
      setSuccess(false);
      setText("Seu cartão foi validado, aperte em Pagar para continuar.");
      setSubmitDisabled(false);
      return false;
    }

    let body = {
      customer: {
        address: {
          line1: values.street,
          number: values.streetNumber,
          postalCode: values.postal_code,
          state: values.state,
          city: values.city,
        },
        documentNumber: values.documentNumber.replace(/(\.|-|\/)/g, "").trim(),
        email: modals.email,
        name: values.name,
      },
      pplan: modals.pplan,
      paymentMethod: "credit_card",
      cardToken,
      installments: modals.installments,
      planPrice: toCent(modals.planPrice),
    };
    setSubmitDisabled(true);
    setLoading(true);

    let authRes, token;
    try {
      authRes = await api.post("/api/v2/auth/signup", {
        email: modals.email,
      });
      token = authRes.data.token;
      setCookie(`_session`, JSON.stringify(token));
      if (modals.discount !== undefined) {
        body = {
          ...body,
          coupon: modals.codeId,
          discount: modals.discount ?? 0,
        };
      }
    } catch (err) {
      setLoading(false);
      setVisible(true);
      setSuccess(false);
      Sentry.captureException(err, { email: modals.email });
      setSubmitDisabled(false);
      if (err.response) {
        setText(err.response.data.data);
      } else if (err.message) {
        setText([err.message]);
      } else {
        setText("Preencha todos os campos obrigatórios!");
      }
    }

    if (isFormValid) {
      if (
        cpfNumNotValid ||
        cardNumberNotValid ||
        cepNotValid ||
        expMonthAndYearNotValid
      ) {
        setLoading(false);
        setVisible(true);
        setSuccess(false);
        setSubmitDisabled(false);
        setText("Ocorreu um erro. Verifique os campos destacados.");
        return false;
      }

      try {
        const paymentRes = await api.post("/api/v2/iugu/subscriptions", body, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const newToken = await api.get("/api/v2/auth/generate", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCookie(
          `_session`,
          JSON.stringify(newToken.data.token),
        );

        if (paymentRes) {
          setVisible(true);
          setSuccess(true);
          setText("Assinatura concluida!");
          setLoading(false);
          setSubmitDisabled(false);
          window.umami(`Assinou: ${modals.pplan}: ${window.location.pathname}`);
          setTimeout(() => {
            if (!window.location.pathname.includes("trilhas")) {
              window.location.href = `https://app.paradigma.education?paid=true&email=${modals.email}`;
            } else {
              window.location.href = `https://app.paradigma.education/trilhas/${
                window.location.pathname.split("/")[2]
              }?paid=true&email=${modals.email}`;
            }
          }, 1500);
        }
      } catch (err) {
        setLoading(false);
        setVisible(true);
        setSuccess(false);
        Sentry.captureException(err, { email: modals.email });
        setSubmitDisabled(false);

        const { action, data, provider } = err.response?.data || {};
        if (data) {
          const jsonString = data.substring(data.indexOf('{'));
          const { message } = JSON.parse(jsonString);
          setText(message);
        }
        console.log(`action: ${action}, data: ${data}, provider: ${provider}`);
        if (!err.response) {
          setText("Preencha todos os campos obrigatórios!");
        }
      }
    } else {
      setLoading(false);
      setVisible(true);
      setSuccess(false);
      setSubmitDisabled(false);
      setText("Preencha todos os campos obrigatórios!");
    }
  };

  return (
    <>
      <div className="flex-center margin-bottom margin-medium">
        <img src={download} loading="lazy" alt="" className="modal-image" />
      </div>
      {visible &&
        (success ? <Message text={text} success /> : <Message text={text} />)}
      <div className="form-wrapper w-form form-wrapper-scroll">
        <form
          id="email-form-2"
          className="form"
          autoComplete="off"
          onSubmit={onSubmit}
        >
          <div className="input-group" style={{ marginBottom: ".75rem" }}>
            <label className="text-size-medium margin-botton margin-xxsmall text-size-line">
              Parcelas
            </label>
            <select
              id="parcelas"
              name="parcelas"
              className="parcel-select"
              onChange={handleChange}
              value={getSelectInstallmentsDefaultValue()}
            >
              {installmentsOptions(12)}
            </select>
          </div>

          <div className="input-group" style={{ marginBottom: ".75rem" }}>
            <label className="text-size-medium margin-botton margin-xxsmall text-size-line">
              Número no cartão
            </label>
            <TextMaskCardNumber
              name="card-number"
              className="text-field w-input"
              data-pagarmecheckout-element="number"
              type="text"
              onChange={onChange}
              data-iugu="number"
              style={{
                color: cardNumberNotValid ? "red" : "#212",
              }}
            />
          </div>

          <div className="input-group" style={{ marginBottom: ".75rem" }}>
            <label className="text-size-medium margin-botton margin-xxsmall text-size-line">
              Nome no cartão
            </label>
            <input
              type="text"
              onChange={onChange}
              name="holder-name"
              className="text-field w-input"
              data-pagarmecheckout-element="number"
              data-iugu="full_name"
            />
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "2fr 1fr ",
              columnGap: "8px",
            }}
          >
            <div className="input-group" style={{ marginBottom: ".75rem" }}>
              <label className="text-size-medium margin-botton margin-xxsmall text-size-line">
                Mês e Ano de vencimento
              </label>
              <TextMaskCardExp
                name="card-exp"
                className="text-field w-input"
                type="text"
                placeholder="MM/AA"
                value={cardExp}
                onChange={onChange}
                data-iugu="expiration"
                style={{
                  color: expMonthAndYearNotValid ? "red" : "#212",
                }}
              />
            </div>

            <div className="input-group" style={{ marginBottom: ".75rem" }}>
              <label className="text-size-medium margin-botton margin-xxsmall text-size-line">
                CVC
              </label>
              <TextMaskCardCvc
                name="cvv"
                data-pagarmecheckout-element="cvv"
                className="text-field w-input"
                type="text"
                data-iugu="verification_value"
                onChange={onChange}
              />
            </div>
          </div>

          <div className="input-group" style={{ marginBottom: ".75rem" }}>
            <label
              htmlFor="documentNumber"
              className="text-size-medium margin-botton margin-xxsmall text-size-line"
            >
              CPF (ou CNPJ)
            </label>
            <TextMaskCardCPF
              id="documentNumber"
              name="documentNumber"
              type="text"
              className="text-field w-input"
              style={{
                color: cpfNumNotValid ? "red" : "#212",
              }}
              placeholder="Número do documento"
              value={cpfNumber}
              onChange={onChange}
              data-name="Email 3"
            />
          </div>

          <>
            <div className="input-group" style={{ marginBottom: ".75rem" }}>
              <label className="text-size-medium margin-botton margin-xxsmall text-size-line">
                Nome completo
              </label>
              <input
                type="text"
                name="name"
                onChange={onChange}
                placeholder="Nome e sobrenome"
                className="text-field w-input"
              />
            </div>

            <div className="input-group" style={{ marginBottom: ".75rem" }}>
              <label className="text-size-medium margin-botton margin-xxsmall text-size-line">
                Código postal
              </label>
              <TextMaskCEP
                name="postal_code"
                className="text-field w-input"
                type="text"
                onBlur={(ev) => onBlurCep(ev)}
                onChange={onChange}
                style={{
                  color: cepNotValid ? "red" : "#212",
                }}
              />
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr",
                columnGap: "8px",
              }}
            >
              <div className="input-group" style={{ marginBottom: ".75rem" }}>
                <label className="text-size-medium margin-botton margin-xxsmall text-size-line">
                  Endereço
                </label>
                <input
                  type="text"
                  name="street"
                  value={values.street}
                  onChange={onChange}
                  placeholder="Nome da rua"
                  className="text-field w-input"
                />
              </div>

              <div className="input-group" style={{ marginBottom: ".75rem" }}>
                <label className="text-size-medium margin-botton margin-xxsmall text-size-line">
                  Número
                </label>
                <input
                  type="text"
                  name="streetNumber"
                  onChange={onChange}
                  placeholder="N° da rua"
                  className="text-field w-input"
                />
              </div>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                columnGap: "8px",
              }}
            >
              <div className="input-group" style={{ marginBottom: ".75rem" }}>
                <label className="text-size-medium margin-botton margin-xxsmall text-size-line">
                  Cidade
                </label>
                <input
                  type="text"
                  name="city"
                  value={values.city}
                  onChange={onChange}
                  className="text-field w-input"
                />
              </div>
              <div className="input-group" style={{ marginBottom: ".75rem" }}>
                <label className="text-size-medium margin-botton margin-xxsmall text-size-line">
                  Estado
                </label>
                <select
                  name="state"
                  onChange={onChange}
                  value={values.state}
                  className="parcel-select"
                >
                  <option value="" disabled selected hidden></option>
                  {states.map((state, index) => (
                    <option key={index} value={state.value}>
                      {state.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>

          <button
            className="button-modal margin-top margin-xsmall w-inline-block"
            type="submit"
            disabled={submitDisabled}
          >
            {!loading ? (
              <span>{`Pagar ${modals.installments}x ${formatter.format(
                modals.price,
              )}`}</span>
            ) : (
              <div className="loader">
                <img
                  style={{ width: "20px" }}
                  src={LoadingGif}
                  alt="loading..."
                />
              </div>
            )}
          </button>
        </form>
      </div>
    </>
  );
};

export default IuguForm;
